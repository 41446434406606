<template>
  <div>
    <div class="card-toolbar mb-5">
        <router-link v-if="$can('sales_shipping.create')"  to="/sales/sales_shipping/create" class="btn btn-primary font-weight-bolder">
              <v-icon>mdi-plus</v-icon>{{ $t('sales_shipping.add_sales_shipping')}}
          </router-link>
        <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5" >
            <span><i class="la la-filter"></i> <span>{{$t('advanced_filter')}}</span></span>
        </button>
    </div>

    <div class="card card-custom mb-5" v-if="showAdvancedSearch">
        <div class="card-body">
            <div class="m-form m-form--fit m--margin-bottom-20">
                <div class="row">

                    <div class="form-group col-md-6 mt-2 mb-2">
                        <label for="invoice_code">{{$t('sales_shipping.name')}}</label>
                        <input v-model="filters.name" type="text" id="invoice_code" class="form-control">
                    </div>

                    <div class="form-group col-md-6 mt-2 mb-2">
                        <label>{{$t('sales_shipping.currency')}}</label>
                        <select name="" id="currency_id" v-model="filters.currency_id" class="custom-select" >
                            <option v-for="row in currencies" :value="row.id" :key="row.id">
                                {{ row.name }}
                            </option>
                        </select>
                    </div>
                    <div class="form-group col-md-6 mt-2 mb-2">
                        <label for="f_status">{{$t('status')}}</label>
                        <select name="" id="f_status" v-model="filters.status" type="text" class="custom-select">
                            <option value="">{{$t('all')}}</option>
                            <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.text }}</option>
                        </select>
                    </div>

                    
                    <div class="form-group d-inline-flex col-md-6 mt-6 mb-0">
                        <button type="button" @click="doFilter" class="btn btn-primary my-auto mb-0 mr-2" id="m_search">
                            <span><i class="fas fa-search"></i>
                                <span>{{$t('search')}}</span>
                            </span>
                        </button>
                        <button type="button" @click="resetFilter" class="btn btn-danger my-auto mb-0 mr-2" id="m_reset">
                            <span><i class="fas fa-trash-restore"></i>
                                <span>{{$t('reset_search')}}</span>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card card-custom">
      <div class="card-body">
          
        <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">

            <template slot="status" slot-scope="props">
<!--              -->
                <b-form-checkbox  v-if="$can('sales_shipping.change_status')"
                    size="lg" @change="changeStatus(props.row.id, props.row.status)"
                    v-model="props.row.status"
                    :name="'check-button'+props.row.id" 
                    switch :key="props.row.id">
                </b-form-checkbox>
                <b-form-checkbox  v-else
                    size="lg" :disabled="true"
                    v-model="props.row.status"
                    :name="'check-button'+props.row.id"
                    switch :key="props.row.id">
                </b-form-checkbox>
            </template>
            <template slot="actions" slot-scope="props">
<!--               -->
              <v-btn icon color="pink" v-if="$can('sales_shipping.update')" :to="`/sales/sales_shipping/edit/${props.row.id}`">
                <v-icon small class="mr-2 text-info" v-b-tooltip.hover :title="$t('edit')">mdi-pencil</v-icon>
              </v-btn>
<!--               -->
                <v-icon small class="text-danger" v-b-tooltip.hover :title="$t('delete')" v-if="$can('sales_shipping.delete')" @click="deleteItem(props.row)">mdi-delete</v-icon>
            </template>
        </v-server-table>

      </div>
    </div>
    <!--end::customer-->
  </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService  from "@/core/services/api.service";

    export default {
        name: "index-sales-shipping",
        components: {},
        data() {
            return {
                mainRoute:'sales/sales_shippings',
                routeChangeStatus : 'sales/sales_shipping/change-status',
                mainRouteDependency: 'base/dependency',

                showAdvancedSearch: false,

                filter: {
                    sortBy: 'id',
                },
                filters: {
                    name: null,
                    currency_id: null,
                    status: null,
                },
                status_list: [
                    {id: 1, text: this.$t('active')},
                    {id: 0, text: this.$t('inactive')},
                ],
                columns: ['name', 'fees', 'currency_name', 'type', 'status', 'actions'],
                data: [],
                currencies: [],
                customers: [],
                users: [],


            }
        },
        computed: {
            options: function () {
                let that = this;
                return {
                    texts: {
                        loadingError: that.$t('Something_went_wrong'),
                        filter: "",
                        filterBy: that.$t('Filter')+' {column}',
                        count: ' ',
                        filterPlaceholder: that.$t('Search_query'),
                        loading: that.$t('Loading')+"...",
                    },
                    headings: {
                        name: that.$t('sales_shipping.name'),
                        fees: that.$t('sales_shipping.fees'),
                        currency_name: that.$t('sales_shipping.currency'),
                        type: that.$t('sales_shipping.type'),
                        status: that.$t('status'),
                        actions: that.$t('actions'),
                    },
                    sortable: ['name'],
                    filterByColumn: false,
                    filterable: false,
                    customFilters: [{
                        name: 'alphabet',

                    }],
                    orderBy: {'column': 'id'},

                    alwaysShowPerPageSelect: false,
                    perPage: 10,
                    pagination: {chunk: 5, dropdown: false},
                    skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
                    sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
                    perPageValues: [5, 10, 15, 25],

                    requestFunction(data) {
                        let _params = {
                            ascending: data.ascending,
                            byColumn: data.byColumn,
                          perPage: data.limit,
                            orderBy: data.orderBy,
                            page: data.page,
                            filter: data.query,
                            ...that.filters,
                        }
                        return ApiService.query(`${that.mainRoute}`, {..._params});

                    },
                    responseAdapter(resp) {
                        return {
                            data: resp.data.data.data,
                            count: resp.data.data.total,
                        }
                    }
                }

            },
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.sales_shipping_management")}]);
            this.getCurrencies();
        },
        methods: {
            changeStatus(id, status) {
                ApiService.patch(this.routeChangeStatus + '/' + id, {
                    status: (status ? 1 : 0),
                }).then((response) => {
                    this.$refs.table.refresh();
                    this.$successAlert(response.data.message);
                }).catch((error) => {
                    this.$errorAlert(error);
                });
            },
          openAdvancedSearch() {
                this.showAdvancedSearch = !this.showAdvancedSearch;
            },
            doFilter() {
                this.$refs.table.refresh();
            },
            resetFilter() {
                this.filters.name = null;
                this.filters.currency_id = null;
                this.filters.status = null;

                this.$refs.table.refresh();
            },
            getFetch() {
                this.$refs.table.refresh();
            },

            deleteItem(item) {
                this.$confirmAlert('', this.actionDelete, item);
            },
            getCurrencies() {
                ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
                    this.currencies = response.data.data;
                });
            },

            actionDelete(item){
                ApiService.delete(`${this.mainRoute}/${item.id}`).then((response) => {
                    this.getFetch();
                    this.$successAlert(response.data.message)
                }).catch((error) => {
                    this.$errorAlert(error)
                })
            },
        },
    };
</script>
